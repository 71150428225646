<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card color="#4e4e4e" title="Usuários">
          <v-toolbar color="#FFF">
            <v-text-field
              hide-details
              prepend-icon
              single-line
              v-model="pesquisa"
            ></v-text-field>
            <v-btn color="#4e4e4e" text @click="findCliente()">Buscar</v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" :items="items">
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.nome }} {{ item.sobrenome }}</td>
              <td>{{ item.telefone }}</td>
              <td v-if="item.cpf">{{ item.cpf }}</td>
              <td v-else>-</td>
              <td v-if="item.user">{{ item.user.email }}</td>
              <td v-else>-</td>
              <td v-if="item.status.status == 0" style="color: green">
                NORMAL
              </td>
              <td v-else-if="item.status.status == 1" style="color: red">
                BLOQUEADO
              </td>
              <td style="width: 50px; height: 50px; object-fit: contain">
                <v-toolbar color="rgba(0,0,0,0)" flat>
                  <v-btn
                    v-if="item.status.status == 0"
                    color="rgba(0,0,0,0)"
                    flat
                    @click="blockUser(item._id)"
                    ><v-icon color="tertiary">mdi-block-helper</v-icon></v-btn
                  >
                  <v-btn
                    v-if="item.status.status == 1"
                    color="rgba(0,0,0,0)"
                    flat
                    @click="unlockUser(item._id)"
                    ><v-icon color="tertiary">mdi-check</v-icon></v-btn
                  >
                  <v-btn
                    color="rgba(0,0,0,0)"
                    flat
                    @click="showCompras(item.user._id)"
                    ><v-icon color="tertiary">mdi-view-list</v-icon></v-btn
                  >
                  <v-btn color="rgba(0,0,0,0)" flat @click="showUser(item._id)"
                    ><v-icon color="tertiary">Ver</v-icon></v-btn
                  >
                </v-toolbar>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Campanha atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false"
          >mdi-close-circle</v-icon
        >
      </v-snackbar>
    </v-layout>

    <v-dialog v-model="comprasrealizadasdialog" width="900">
      <v-list v-if="comprasrealizadas.length > 0">
        <v-card-text>Compras realizadas</v-card-text>
        <v-list-group
          v-for="item in comprasrealizadas"
          :key="item.codigoVenda"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title style="font-weight: bold;">Código de Venda: {{ item.codigoVenda }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="subItem in item.produtos"
            :key="subItem.title"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ subItem.id_prod.nome }} - R$ {{(subItem.valor.toFixed(2)).toString().replace('.',',')}} - Qnt: {{subItem.qntSolicitada}}</v-list-tile-title>
            </v-list-tile-content>

            <!-- <v-list-tile-action>
              <v-icon>{{ subItem.action }}dsds</v-icon>
            </v-list-tile-action> -->
          </v-list-tile>
        </v-list-group>
      </v-list>
      <v-list v-else>
      <v-card-text>Nenhuma compra realizada</v-card-text>
      </v-list>
    </v-dialog>

    <v-dialog v-if="selecionado" v-model="dialog" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >{{ selecionado.nome }} {{ selecionado.sobrenome }}</v-card-title
        >
        <v-card-text v-if="selecionado">
          Nome:
          <v-text-field v-model="selecionado.nome"></v-text-field>
        </v-card-text>

        <v-card-text v-if="selecionado">
          Telefone:
          <v-text-field v-model="selecionado.telefone"></v-text-field>
        </v-card-text>
        <v-card-text v-if="selecionado">
          Email:
          <v-text-field v-model="selecionado.user.email"></v-text-field>
        </v-card-text>
        <v-card-text v-if="selecionado">
          Tipo:
          <v-flex
            v-if="selecionado.user && selecionado.user.tipo"
            xs12
            sm6
            d-flex
          >
            <select
              v-if="selecionado.user && selecionado.user.tipo"
              v-model="selecionado.user.tipo"
              style="border-width: 1"
            >
              <option
                v-for="tipo in this.tipos"
                v-bind:key="tipo.valor"
                :value="tipo.valor"
              >
                {{ tipo.nome }}
              </option>
            </select>
          </v-flex>
        </v-card-text>

        <v-card-text v-if="selecionado">
          Status:
          <v-flex v-if="selecionado.status" xs12 sm6 d-flex>
            <select v-model="selecionado.status.status" style="border-width: 1">
              <option
                v-for="tipo in this.status"
                v-bind:key="tipo.valor"
                :value="tipo.valor"
              >
                {{ tipo.nome }}
              </option>
            </select>
          </v-flex>
        </v-card-text>

        <v-card-text v-if="selecionado">
          Descrição do status:
          <v-text-field v-model="selecionado.status.descricao"></v-text-field>
        </v-card-text>

        <!-- <input
          class="ml-3"
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />

        <v-card-text v-if="selecionado">
          <b>Produtos:</b>
        </v-card-text>

        <v-text-field
          v-model="codigoDeBarras"
          class="ml-3 mr-5"
          placeholder="Adicionar Produto"
        ></v-text-field>
        <v-btn color="primary" class="ml-3" @click="adicionarProduto()"
          >Adicionar</v-btn
        >
        <div v-if="selecionado">
          <v-data-table
            :headers="headers_prod"
            :items="selecionado.produtos"
            :items-per-page="5"
            class="elevation-1"
          >
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.nome }}</td>
              <td>{{ item.codigoDeBarras }}</td>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="atualizarCliente()"
            >Atualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCriar" width="900">
      <v-card>
        <!-- <v-card-title class="headline grey lighten-2" primary-title
          >Criar Campanha</v-card-title
        >
        <v-card-text>
          Nome:
          <v-text-field v-model="criar.nome"></v-text-field>
        </v-card-text>
        <v-card-text>
          Descrição:
          <v-text-field v-model="criar.descricao"></v-text-field>
        </v-card-text>

        <v-card-text>
          Porcentagem:
          <v-text-field
            v-model="criar.porcentagem"
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-row justify="center">
          <v-card-text>Início:</v-card-text>

          <v-date-picker
            v-model="criar.begin"
            class="ml-3"
            dark
          ></v-date-picker>
        </v-row>
        <v-row justify="center">
          <v-card-text>Fim:</v-card-text>
          <v-date-picker v-model="criar.end" class="ml-3" dark></v-date-picker>
        </v-row>
        <br />

        <input
          class="ml-3 mt-4"
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="criarCampanha()">Criar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogeditar: false,
    dialogCriar: false,
    comprasrealizadasdialog: false,
    comprasrealizadas:[],
    pesquisa: "",
    codigoDeBarras: "",
    headers_prod: [
      {
        text: "Nome",
        sortable: false,
        value: "nome",
      },
      { text: "Código de Barras", sortable: false, value: "codigoDeBarras" },
      // { text: "Actions", value: "actions", sortable: false }
    ],
    file: "",
    headers: [
      {
        sortable: false,
        text: "Nome",
        value: "nome",
      },
      {
        sortable: false,
        text: "Telefone",
        value: "telefone",
      },
      {
        sortable: false,
        text: "CPF",
        align: "center",
        value: "cpf",
      },
      {
        sortable: false,
        text: "E-mail",
        align: "center",
        value: "email",
      },
      {
        sortable: false,
        text: "Status",
        value: "status",
      },
      {
        sortable: false,
        text: "Ações",
      },
    ],
    tipos: [
      {
        nome: "Administrador",
        valor: "administrador",
      },
      {
        nome: "Vendedor",
        valor: "vendedor",
      },
      {
        nome: "Cliente",
        valor: "cliente",
      },
    ],
    status: [
      {
        nome: "Normal",
        valor: 0,
      },
      {
        nome: "Bloqueado",
        valor: 1,
      },
    ],
    tipoSelecionado: "",
    snackbar: false,
    codigoDeBarras: null,
    snackbarerror: false,
    categorias: [],
    criar: {
      nome: "",
      descricao: "",
      porcentagem: 0,
      ativa: true,
      begin: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10),
    },
    select: null,
    selecionado: null,
    items: [],
    bottom: false,
    top: false,
    left: false,
    right: false,
  }),
  beforeMount() {
    this.getClientes();
  },
  methods: {
    getClientes() {
      this.$http
        .get("/clientes")
        .then((res) => {
          this.items = res.data.clientes;
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    findCliente() {
      if (this.pesquisa != "") {
        this.$http
          .get(`/clientes/busca/${this.pesquisa}`)
          .then((res) => {
            this.items = res.data;
          })
          .catch((err) => {
            if(err.error) alert(err.error)
            
          });
      } else {
        this.getClientes();
      }
    },
    atualizarCliente() {
      this.$http
        .put(`/cliente/${this.selecionado._id}`, this.selecionado)
        .then((res) => {
          alert("Atualizado!");
        })
        .catch((err) => alert(err.error));
    },
    showUser(item) {
      try {
        this.$http
          .get(`/cliente/${item}`)
          .then((res) => {
            // console.log(res.data);
            this.selecionado = res.data.cliente;
            this.dialog = true;
          })
          .catch((err) => {
            if(err.error) alert(err.error)
            
          });
      } catch (err) {}
    },
    showCompras(item) {
      // console.log(item);
      this.$http
        .get(`/vendas/detalhes/all/${item}`)
        .then(res => {
          // console.log(res.data);
          this.comprasrealizadas = res.data;
          this.comprasrealizadasdialog = true;
        }).catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    fecharDialog() {
      this.dialog = false;
    },
    blockUser(id) {
      this.$http
        .put(`/cliente/bloquear/${id}`, { descricao: "" })
        .then((res) => {
          let it = this.items;
          for (let e of it) {
            if (res.data.id == e.id) {
              e.status = res.data.status;
            }
          }

          this.items = it;
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    unlockUser(id) {
      this.$http
        .put(`/cliente/desbloquear/${id}`)
        .then((res) => {
          let it = this.items;
          for (let e of it) {
            if (res.data.id == e.id) {
              e.status = res.data.status;
            }
          }

          this.items = it;
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
  },
};
</script>
